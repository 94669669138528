import React, { Component } from "react";
import Button from "react-bootstrap/Button";

import { isAdmin } from "../services/authService";
import UTable from "./common/table";
import { prepareDate } from "../utils/dateFormat";

class AuthDevicesUpdateTable extends Component {
  columns = [
    {
      path: "sn",
      label: "SN",
      content: (device) => {
        let style = { color: "black" };
        if (device.conf_errors) {
          style.color = "red";
        }
        return <div style={style}>{device.sn}</div>;
      },
    },
    { path: "dev_type", label: "Typ" },
    {
      path: "last_seen",
      label: "Ostatnio widziany",
      content: (device) => {
        return <div>{prepareDate(device.last_seen)}</div>;
      },
    },
    { path: "fw_version", label: "FW" },
    {
      path: "update_fw",
      label: "Aktualizacja",
      content: (device) => {
        if (this.props.fwFiles.length === 0) return null;
        const fwFile = this.props.fwFiles.filter(
          (fw) => fw._id === device.update_fw
        );
        if (fwFile.length !== 0) return fwFile[0].version;
        return null;
      },
    },
    { path: "hw_version", label: "HW" },
    {
      path: "shop_name",
      label: "Nr. drogerii"
    },
    

    // {
    //   path: "",
    //   key: "Add",
    //   content: (device) => (
    //     <Button
    //       variant="primary"
    //       className="my-0"
    //       size="sm"
    //       onClick={() => this.props.onAdd(device)}
    //     >
    //       Dodaj
    //     </Button>
    //   ),
    // },
  ];

  checkedColumn = {
    path: "checked",
    label: "",
    content: (device) => {
      if (!this.props.checkedVisible) return null;
      return (
        <input
          onChange={(event) => this.props.onCheckClick(event, device)}
          type="checkbox"
          checked={device.checked ? true : false}
        />
      );
    },
  };

  constructor() {
    super();

    if (isAdmin()) {
      this.columns.unshift(this.checkedColumn);
    }
  }

  render() {
    const { authDevices, sortColumn, onSort, onRowClick } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={authDevices}
        dataId="_id"
        onRowClick={onRowClick}
      />
    );
  }
}

export default AuthDevicesUpdateTable;
