import React, {Component} from "react";
import Joi from "joi";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faSave,
    faTimes,
    faPlusSquare,
    faArrowUp,
    faCaretUp,
    faCaretDown
} from "@fortawesome/free-solid-svg-icons";

import UForm from "./common/uform";
import SearchBox from "./common/searchBox";
import config from "../config.json";

import ConfirmationButton from "./common/confirmationButton";
import dictionary from "../PL.json";

import * as AuthDeviceService from "../services/authDeviceService";
import * as DeviceDefaultConfigurationService from "../services/deviceDefaultConfigurationService";
import * as FileService from "../services/fileService";

import {toast} from "react-toastify";

import AuthDevicesContext from "../contexts/authDevicesContext";
import {prepareDate} from "../utils/dateFormat";
import {getName as getNameOfDevType} from "../utils/devType";

import SimpleSelect from "./common/simpleSelect";

import * as deviceConf from "../utils/conf/deviceConf";
import * as sensorSettingsOnChange from "../utils/conf/distanceSensorSettingsOnChangeAction";

import ProgressModal from "./common/progressModal";

import ManyGenerateTimeoutBlock from "./common/confControl/manyGenerateTimeoutBlock";
import ManyGenerateTitle from "./common/confControl/manyGenerateTitle";
import ManyGenerateDistanceBlock from "./common/confControl/manyGenerateDistanceBlock";
import ManyGenerateIsActiveBlock from "./common/confControl/manyIsActiveBlock";
import ManyGenerateFileChangeBlock from "./common/confControl/manyGenerateFileChangeBlock";
import ManyGenerateDistanceBlockMK from "./common/confControl/manyGenerateDistanceBlockMK";
import ManyGenerateBlockSensorBlockMK from "./common/confControl/manyGenerateBlockSensorBlockMK";
import InputH from "./common/inputHorizontal";

class AuthDevicesModifyModal extends Component {
    static contextType = AuthDevicesContext;

    state = {
        enable: {
            beepFile: [false, false, false, false, false, false],
            beepEnable: [false, false, false, false, false, false],
            beepDetectionFile: false,
            beepDetectionEnable: false,
            cashier: {maxRange: false, activeRange: false, enable: false, blockSensor: false},
            client: {maxRange: false, activeRange: false, enable: false, blockSensor: false},
            ceiling: {maxRange: false, activeRange: false, enable: false},
            dt: false,
            bdt: false,
            cdt: false,
            playlistEnable: false,
            playlistIMSEnable: false
        },
        confError: {
            beepFile: [false, false, false, false, false, false],
            beepEnable: [false, false, false, false, false, false],
            beepDetectionFile: false,
            beepDetectionEnable: false,
            cashier: {maxRange: false, activeRange: false, enable: false, blockSensor: false},
            client: {maxRange: false, activeRange: false, enable: false, blockSensor: false},
            ceiling: {maxRange: false, activeRange: false, enable: false},
            dt: false,
            bdt: false,
            cdt: false,
            playlistEnable: false,
            playlistIMSEnable: false
        },
        confValue: {
            beepFile: ["", "", "", "", "", ""],
            beepEnable: [false, false, false, false, false, false],
            beepDetectionFile: "",
            beepDetectionEnable: false,
            cashier: {
                maxRange: [0, 0, 0, 0],
                activeRange: [0, 0, 0, 0],
                enable: false,
                blockSensor: [false, false, false, false]
            },
            client: {
                maxRange: [0, 0, 0, 0],
                activeRange: [0, 0, 0, 0],
                enable: false,
                blockSensor: [false, false, false, false]
            },
            ceiling: {maxRange: 0, activeRange: 0, enable: false},
            dt: 120,
            bdt: 20,
            cdt: 2,
            playlistEnable: false,
            playlistIMSEnable: false
        },
        files: [],
        showConfirmationModal: false,
        dataCountModifyTillNow: 0,
        dataCountToModify: 0,

        tagEdit: false,
        selectedTags: [],
        selectedTag: "",

        editDefaultConfigurations: false,
        existingConfiguratons: [],
        selectedExistingConfiguration: "",
        newConfigurationName: ""

    };

    updateSettings = async (device, startup) => {
        const context = this.context;

        const enable = {
            beepFile: [false, false, false, false, false, false],
            beepEnable: [false, false, false, false, false, false],
            beepDetectionFile: false,
            beepDetectionEnable: false,
            cashier: {maxRange: false, activeRange: false, enable: false, blockSensor: false},
            client: {maxRange: false, activeRange: false, enable: false, blockSensor: false},
            ceiling: {maxRange: false, activeRange: false, enable: false},
            dt: false,
            bdt: false,
            cdt: false,
            playlistEnable: false,
            playlistIMSEnable: false
        };

        const confValue =  {
            beepFile: ["", "", "", "", "", ""],
                beepEnable: [false, false, false, false, false, false],
                beepDetectionFile: "",
                beepDetectionEnable: false,
                cashier: {
                maxRange: [0, 0, 0, 0],
                    activeRange: [0, 0, 0, 0],
                    enable: false,
                    blockSensor: [false, false, false, false]
            },
            client: {
                maxRange: [0, 0, 0, 0],
                    activeRange: [0, 0, 0, 0],
                    enable: false,
                    blockSensor: [false, false, false, false]
            },
            ceiling: {maxRange: 0, activeRange: 0, enable: false},
            dt: 120,
                bdt: 20,
                cdt: 2,
                playlistEnable: false,
                playlistIMSEnable: false
        };

        const {data: files} = await FileService.getAll();

        this.setState({enable, files,confValue});

        if (this.context.selectedDevType?.type === "MK") {
        }
    };

    handleEnter = async () => {
        if (this.context.tagEdit) {
            this.setState({selectedTags: [], selectedTag: ""});
        }

        this.setState({editDefaultConfigurations: false});
        await this.updateSettings(null, true);
    };

    prepareDeviceList = (devType) => {
        const devices = this.context.devices.filter((device) => device.checked && device.dev_type === devType);
        return devices;
    };

    prepareDeviceListOfAnyType = () => {
        const devices = this.context.devices.filter((device) => device.checked);
        return devices;
    };

    handleSubmit = async () => {
        const context = this.context;

        const {confValue, enable} = this.state;

        const devices = this.prepareDeviceList(this.context.selectedDevType.type);
        if (devices.length === 0) return;

        let counter = 0;
        this.setState({
            showConfirmationModal: true, dataCountToModify: devices.length, dataCountModifyTillNow: counter,
        });

        if (this.context.selectedDevType?.type === "MK") {
            for await (const device of devices) {
                try {
                    if (device.conf) {
                        const conf = _.cloneDeep(device.conf);
                        enable.beepFile.forEach((enabled, index) => {
                            if (!enabled) return;
                            if (confValue.beepFile[index] !== null || confValue.beepFile[index] !== undefined) {
                                conf.beepTable[index].file = confValue.beepFile[index];
                            }
                        });

                        if (enable.beepDetectionFile) {
                            const index = conf.beepTable.findIndex((x) => x.id === config.beepTypes.beepDetectionScenerio1_ID);
                            if (index !== -1) {
                                conf.beepTable[index].file = confValue.beepDetectionFile;
                            }
                        }

                        if (enable.cashier.activeRange || enable.cashier.maxRange || enable.cashier.enable || enable.cashier.blockSensor) {
                            const index = conf.distanceSensorTable.findIndex((x) => x.id === config.distanceSensorTypes.cashier_ID);
                            if (index !== -1) {
                                if (enable.cashier.maxRange) conf.distanceSensorTable[index].maxRange = confValue.cashier.maxRange;
                                if (enable.cashier.activeRange) conf.distanceSensorTable[index].activeRange = confValue.cashier.activeRange;
                                if (enable.cashier.enable === true) conf.distanceSensorTable[index].enable = confValue.cashier.enable;
                                if (enable.cashier.blockSensor === true) conf.distanceSensorTable[index].blockSensor = confValue.cashier.blockSensor;
                            }
                        }

                        if (enable.client.activeRange || enable.client.maxRange || enable.client.enable || enable.client.blockSensor) {
                            const index = conf.distanceSensorTable.findIndex((x) => x.id === config.distanceSensorTypes.client_ID);
                            if (index !== -1) {
                                if (enable.client.maxRange) conf.distanceSensorTable[index].maxRange = confValue.client.maxRange;
                                if (enable.client.activeRange) conf.distanceSensorTable[index].activeRange = confValue.client.activeRange;
                                if (enable.client.enable === true) conf.distanceSensorTable[index].enable = confValue.client.enable;
                                if (enable.client.blockSensor === true) conf.distanceSensorTable[index].blockSensor = confValue.client.blockSensor;
                            }
                        }

                        if (enable.dt) {
                            conf.dt = Number(confValue.dt);
                        }

                        if (enable.cdt) {
                            conf.cdt = Number(confValue.cdt);
                        }

                        if (enable.bdt) {
                            conf.bdt = Number(confValue.bdt);
                        }

                        const data = await this.sendConf(device, conf);
                        if (data) this.setState({dataCountModifyTillNow: counter++});
                    }
                } catch (ex) {
                    toast.error("Wystąpił nieoczekiwany problem !!!");
                }
            }
        }

        if (this.context.selectedDevType?.type === "MS") {
            for await (const device of devices) {
                try {
                    if (device.conf) {
                        const conf = _.cloneDeep(device.conf);

                        if (enable.beepDetectionFile) {
                            const index = conf.beepTable.findIndex((x) => x.id === config.beepTypes.beepDetectionScenerio1_ID);
                            if (index !== -1) {
                                conf.beepTable[index].file = confValue.beepDetectionFile;
                            }
                        }

                        if (enable.ceiling.activeRange || enable.ceiling.enable) {
                            const index = conf.distanceSensorTable.findIndex((x) => x.id === config.distanceSensorTypes.ceiling_ID);
                            if (index !== -1) {
                                if (enable.ceiling.activeRange) conf.distanceSensorTable[index].activeRange = Number(confValue.ceiling.activeRange);
                                if (enable.ceiling.enable === true) conf.distanceSensorTable[index].enable = confValue.ceiling.enable;
                            }
                        }

                        if (enable.dt) {
                            conf.dt = Number(confValue.dt);
                        }

                        const data = await this.sendConf(device, conf);
                        if (data) this.setState({dataCountModifyTillNow: counter++});
                    }
                } catch (ex) {
                    toast.error("Wystąpił nieoczekiwany problem !!!");
                }
            }
        }

        if (this.context.selectedDevType?.type === "MKS") {
            for await (const device of devices) {
                try {
                    if (device.conf) {
                        const conf = _.cloneDeep(device.conf);
                        enable.beepFile.forEach((enabled, index) => {
                            if (!enabled) return;
                            if (confValue.beepFile[index] !== null || confValue.beepFile[index] !== undefined) {
                                conf.beepTable[index].file = confValue.beepFile[index];
                            }
                        });


                        const data = await this.sendConf(device, conf);
                        if (data) this.setState({dataCountModifyTillNow: counter++});
                    }
                } catch (ex) {
                    toast.error("Wystąpił nieoczekiwany problem !!!");
                }
            }
        }

        if (this.context.selectedDevType?.type === "MA") {
            // spkVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            //     spkName: ["Kanał 1:", "Kanał 2:", "Kanał 3:", "Kanał 4:", "Kanał 5:", "Kanał 6:", "Kanał 7:", "Kanał 8:", "Kanał 9:", "Kanał 10:", "Kanał 11:", "Kanał 12:"],
            //     spkAudioEnable: [false, false, false, false, false, false, false, false, false, false, false, false],
            //     playlistEnable: false,
            //     playlistIMSEnable: false,
            //     spkPlaylistVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            //     spkPlaylistAudioEnable: [false, false, false, false, false, false, false, false, false, false, false, false],

            for await (const device of devices) {
                try {
                    if (device.conf) {
                        const conf = _.cloneDeep(device.conf);

                        if (enable.playlistEnable) {
                            conf.playlistEnable = confValue.playlistEnable;
                        }

                        if (enable.playlistIMSEnable) {
                            conf.playlistIMSEnable = confValue.playlistIMSEnable;
                        }
                        if (conf.spkAudioEnable !== undefined && conf.spkAudioEnable !== null && conf.spkAudioEnable.length > 0) {

                            if (conf.spkPlaylistAudioEnable === undefined || conf.spkPlaylistAudioEnable === null) {
                                conf.spkPlaylistAudioEnable = [];
                                for (let i = 0; i < conf.spkAudioEnable.length; i++) {
                                    conf.spkPlaylistAudioEnable.push(false);
                                }
                            }

                            if (conf.spkPlaylistVolume === undefined || conf.spkPlaylistVolume === null) {
                                conf.spkPlaylistVolume = [];
                                for (let i = 0; i < conf.spkAudioEnable.length; i++) {
                                    conf.spkPlaylistVolume.push(0);
                                }
                            }
                        }
                        const data = await this.sendConf(device, conf);
                        if (data) this.setState({dataCountModifyTillNow: counter++});
                    }
                } catch (ex) {
                    toast.error("Wystąpił nieoczekiwany problem !!!");
                }
            }
        }

        // window.location = "/authDevices";

        this.setState({showConfirmationModal: false});
    };

    handleDeleteDefaultConfiguration = async (id) => {
        const context = this.context;

        try {

            let data = null;
            if (id !== undefined && id !== null && id !== "") {
                const index = this.state.existingConfiguratons.findIndex((x) => x._id === id)

                data = await this.deleteDefaultDeviceConfiguration(id);
            } else {
                return;
            }

            if (data) toast.success("Konfiguracja została usunięta !!!");

            this.getDeviceDefaultConfigurations();
            this.setState({selectedExistingConfiguration: ""});
        } catch (ex) {
            toast.error("Wystąpił nieoczekiwany problem !!!");
        }

    }
    handleSubmitDefaultConfiguration = async (id) => {
        const context = this.context;

        const {confValue, enable} = this.state;


        if (this.context.selectedDevType?.type === "MK") {
            try {
                const conf = {
                    beepTable: [], distanceSensorTable: [], dt: 120, bdt: 20, cdt: 2,
                };

                enable.beepFile.forEach((enabled, index) => {
                    conf.beepTable.push({
                        id: index + 1, file: "", to: [],
                    });

                    if (confValue.beepFile[index] !== null || confValue.beepFile[index] !== undefined) {
                        conf.beepTable[index].file = confValue.beepFile[index];
                    }
                });

                conf.beepTable.push({
                    id: config.beepTypes.beepDetectionScenerio1_ID, file: confValue.beepDetectionFile, to: [],
                });

                conf.distanceSensorTable.push({
                    id: config.distanceSensorTypes.cashier_ID,
                    maxRange: [...confValue.cashier.maxRange],
                    activeRange: [...confValue.cashier.activeRange],
                    blockSensor: [...confValue.cashier.blockSensor],
                    enable: confValue.cashier.enable
                })

                conf.distanceSensorTable.push({
                    id: config.distanceSensorTypes.client_ID,
                    maxRange: [...confValue.client.maxRange],
                    activeRange: [...confValue.client.activeRange],
                    blockSensor: [...confValue.client.blockSensor],
                    enable: confValue.client.enable
                })

                conf.dt = Number(confValue.dt);
                conf.cdt = Number(confValue.cdt);
                conf.bdt = Number(confValue.bdt);

                let data = null;
                if (id !== undefined && id !== null && id !== "") {
                    const index = this.state.existingConfiguratons.findIndex((x) => x._id === id)

                    data = await this.modifyDefaultDeviceConfiguration({
                        dev_type: this.state.existingConfiguratons[index].dev_type,
                        name: this.state.existingConfiguratons[index].name,
                        value: {conf: conf, tags: [...this.state.selectedTags]}
                    }, id);
                } else {
                    const index = this.state.existingConfiguratons.findIndex((x) => (x.name === this.state.newConfigurationName) && (x.dev_type === this.context.selectedDevType.type))

                    if (index !== -1) {
                        toast.error("Konfiguracja o podanej nazwie już istnieje !!!");
                        return;
                    }


                    data = await this.sendDefaultDeviceConfiguration({
                        dev_type: "MK",
                        name: this.state.newConfigurationName,
                        value: {conf: conf, tags: [...this.state.selectedTags]}
                    });
                }


                if (data) toast.success("Konfiguracja została zapisana !!!");

                this.getDeviceDefaultConfigurations();
                this.setState({selectedExistingConfiguration: data._id});
            } catch (ex) {
                toast.error("Wystąpił nieoczekiwany problem !!!");
            }

        }

        // if (this.context.selectedDevType?.type === "MS") {
        //     for await (const device of devices) {
        //         try {
        //             if (device.conf) {
        //                 const conf = _.cloneDeep(device.conf);
        //
        //                 if (enable.beepDetectionFile) {
        //                     const index = conf.beepTable.findIndex(
        //                         (x) => x.id === config.beepTypes.beepDetectionScenerio1_ID
        //                     );
        //                     if (index !== -1) {
        //                         conf.beepTable[index].file = confValue.beepDetectionFile;
        //                     }
        //                 }
        //
        //                 if (enable.ceiling.activeRange || enable.ceiling.enable) {
        //                     const index = conf.distanceSensorTable.findIndex(
        //                         (x) => x.id === config.distanceSensorTypes.ceiling_ID
        //                     );
        //                     if (index !== -1) {
        //                         if (enable.ceiling.activeRange)
        //                             conf.distanceSensorTable[index].activeRange = Number(
        //                                 confValue.ceiling.activeRange
        //                             );
        //                         if (enable.ceiling.enable === true)
        //                             conf.distanceSensorTable[index].enable = confValue.ceiling.enable;
        //                     }
        //                 }
        //
        //                 if (enable.dt) {
        //                     conf.dt = Number(confValue.dt);
        //                 }
        //
        //                 const data = await this.sendConf(device, conf);
        //                 if (data) this.setState({dataCountModifyTillNow: counter++});
        //             }
        //         } catch (ex) {
        //             toast.error("Wystąpił nieoczekiwany problem !!!");
        //         }
        //     }
        // }
        //
        // if (this.context.selectedDevType?.type === "MKS") {
        //     for await (const device of devices) {
        //         try {
        //             if (device.conf) {
        //                 const conf = _.cloneDeep(device.conf);
        //                 enable.beepFile.forEach((enabled, index) => {
        //                     if (!enabled) return;
        //                     if (confValue.beepFile[index] !== null || confValue.beepFile[index] !== undefined) {
        //                         conf.beepTable[index].file = confValue.beepFile[index];
        //                     }
        //                 });
        //
        //
        //                 const data = await this.sendConf(device, conf);
        //                 if (data) this.setState({dataCountModifyTillNow: counter++});
        //             }
        //         } catch (ex) {
        //             toast.error("Wystąpił nieoczekiwany problem !!!");
        //         }
        //     }
        // }
        //
        // if (this.context.selectedDevType?.type === "MA") {
        //     // spkVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //     //     spkName: ["Kanał 1:", "Kanał 2:", "Kanał 3:", "Kanał 4:", "Kanał 5:", "Kanał 6:", "Kanał 7:", "Kanał 8:", "Kanał 9:", "Kanał 10:", "Kanał 11:", "Kanał 12:"],
        //     //     spkAudioEnable: [false, false, false, false, false, false, false, false, false, false, false, false],
        //     //     playlistEnable: false,
        //     //     spkPlaylistVolume: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        //     //     spkPlaylistAudioEnable: [false, false, false, false, false, false, false, false, false, false, false, false],
        //
        //     for await (const device of devices) {
        //         try {
        //             if (device.conf) {
        //                 const conf = _.cloneDeep(device.conf);
        //
        //                 if (enable.playlistEnable) {
        //                     conf.playlistEnable = confValue.playlistEnable;
        //                 }
        //                 if (conf.spkAudioEnable !== undefined &&
        //                     conf.spkAudioEnable !== null &&
        //                     conf.spkAudioEnable.length > 0) {
        //
        //                     if (conf.spkPlaylistAudioEnable === undefined || conf.spkPlaylistAudioEnable === null) {
        //                         conf.spkPlaylistAudioEnable = [];
        //                         for (let i = 0; i < conf.spkAudioEnable.length; i++) {
        //                             conf.spkPlaylistAudioEnable.push(false);
        //                         }
        //                     }
        //
        //                     if (conf.spkPlaylistVolume === undefined || conf.spkPlaylistVolume === null) {
        //                         conf.spkPlaylistVolume = [];
        //                         for (let i = 0; i < conf.spkAudioEnable.length; i++) {
        //                             conf.spkPlaylistVolume.push(0);
        //                         }
        //                     }
        //                 }
        //                 const data = await this.sendConf(device, conf);
        //                 if (data) this.setState({dataCountModifyTillNow: counter++});
        //             }
        //         } catch (ex) {
        //             toast.error("Wystąpił nieoczekiwany problem !!!");
        //         }
        //     }
        // }

    };

    saveTags = async () => {
        const context = this.context;

        const {selectedTags} = this.state;

        const devices = this.prepareDeviceList(this.context.selectedDevType.type);
        if (devices.length === 0) return;

        if (!context.tagEdit) return;
        if (selectedTags.length === 0) return;

        let counter = 0;

        this.setState({
            showConfirmationModal: true, dataCountToModify: devices.length, dataCountModifyTillNow: counter,
        });

        for await (const device of devices) {
            try {
                let tags = [];
                if (device.tags) {
                    tags = _.cloneDeep(device.tags);
                }

                selectedTags.forEach((selectedTag) => {
                    if (device.tags.includes(selectedTag)) return;
                    tags.push(selectedTag);
                });

                const {data} = await AuthDeviceService.updateTags({
                    _id: device._id, tags,
                });

                if (data) this.setState({dataCountModifyTillNow: counter++});
            } catch (ex) {
                toast.error("Wystąpił nieoczekiwany problem !!!");
            }
        }

        window.location = "/authDevices";

        this.setState({showConfirmationModal: false});
    };

    removeTags = async () => {
        const context = this.context;

        const {selectedTags} = this.state;

        const devices = this.prepareDeviceList(this.context.selectedDevType.type);
        if (devices.length === 0) return;

        if (!context.tagEdit) return;
        if (selectedTags.length === 0) return;

        let counter = 0;

        this.setState({
            showConfirmationModal: true, dataCountToModify: devices.length, dataCountModifyTillNow: counter,
        });

        for await (const device of devices) {
            try {
                let tags = [];
                if (device.tags) {
                    tags = _.cloneDeep(device.tags);
                }

                selectedTags.forEach((selectedTag) => {
                    const index = tags.findIndex((x) => x === selectedTag);
                    if (index !== -1) tags.splice(index, 1);
                });

                const {data} = await AuthDeviceService.updateTags({
                    _id: device._id, tags,
                });

                if (data) this.setState({dataCountModifyTillNow: counter++});
            } catch (ex) {
                toast.error("Wystąpił nieoczekiwany problem !!!");
            }
        }

        window.location = "/authDevices";

        this.setState({showConfirmationModal: false});
    };

    removeAllTags = async () => {
        const context = this.context;

        const {selectedTags} = this.state;

        const devices = this.prepareDeviceList(this.context.selectedDevType.type);
        if (devices.length === 0) return;

        if (!context.tagEdit) return;

        let counter = 0;

        this.setState({
            showConfirmationModal: true, dataCountToModify: devices.length, dataCountModifyTillNow: counter,
        });

        for await (const device of devices) {
            try {
                console.log(device);
                let tags = [];

                const {data} = await AuthDeviceService.updateTags({
                    _id: device._id, tags,
                });

                if (data) this.setState({dataCountModifyTillNow: counter++});
            } catch (ex) {
                toast.error("Wystąpił nieoczekiwany problem !!!");
            }
        }

        window.location = "/authDevices";

        this.setState({showConfirmationModal: false});
    };

    sendConf = async (device, conf) => {
        try {
            const {data} = await AuthDeviceService.postConf({
                _id: device._id, conf,
            });

            return data;
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd:" + ex);
            }
        }

        return null;
    };

    sendDefaultDeviceConfiguration = async (conf) => {
        try {
            const {data} = await DeviceDefaultConfigurationService.add(conf);

            return data;
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd:" + ex);
            }
        }

        return null;
    };

    modifyDefaultDeviceConfiguration = async (conf, id) => {
        try {
            const {data} = await DeviceDefaultConfigurationService.modify(conf, id);

            return data;
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd:" + ex);
            }
        }

        return null;
    };

    deleteDefaultDeviceConfiguration = async (id) => {
        try {
            const {data} = await DeviceDefaultConfigurationService.remove(id);

            return data;
        } catch (ex) {
            if (ex.response && ex.response.status === 400) {
                toast.error("Błąd:" + ex);
            }
        }

        return null;
    };

    isDiffStyle = (value, baseStyle) => {
        if (value) return baseStyle + " bg-success"; else return baseStyle;
    };

    handleTimeoutChange = (value, name, min, max) => {
        const {confValue: conf, confError} = this.state;
        if (value < min) {
            confError[name] = true;
        } else if (value > max) {
            confError[name] = true;
        } else {
            confError[name] = false;
        }
        conf[name] = value;
        this.setState({
            confValue: conf,
        });
    };

    handleDistanceChange = (value, no, name, type, min, max) => {
        const {confValue: conf, confError} = this.state;
        if (value < min) {
            confError[type][name] = true;
        } else if (value > max) {
            confError[type][name] = true;
        } else {
            confError[type][name] = false;
        }

        conf[type][name][no] = value;
        this.setState({
            confValue: conf,
        });
    };

    handleDistanceChangeMS = (value, name, type, min, max) => {
        const {confValue: conf, confError} = this.state;
        if (value < min) {
            confError[type][name] = true;
        } else if (value > max) {
            confError[type][name] = true;
        } else {
            confError[type][name] = false;
        }

        conf[type][name] = value;
        this.setState({
            confValue: conf,
        });
    };

    handleBoolValue = (value, name, type) => {
        const conf = this.state.confValue;
        conf[name] = value;
        this.setState({confValue: conf});
    };

    handlePlaylistValueChange = (value, name, type) => {
        const conf = this.state.confValue;
        conf[name] = value;

        if(value === true) {
            conf.playlistIMSEnable = false;
            const tempEnable = this.state.enable;
            tempEnable.playlistEnable = true;
            tempEnable.playlistIMSEnable = true;
            this.setState({enable: tempEnable});
        }
        this.setState({confValue: conf});
    };

    handleIMSPlaylistValueChange = (value, name, type) => {
        const conf = this.state.confValue;
        conf[name] = value;

        if(value === true) {
            conf.playlistEnable = false;
            const tempEnable = this.state.enable;
            tempEnable.playlistEnable = true;
            tempEnable.playlistIMSEnable = true;
        }
        this.setState({confValue: conf});
    };

    handleNestedBoolValue = (value, name, type) => {
        const conf = this.state.confValue;
        conf[type][name] = value;
        this.setState({confValue: conf});
    };

    handleNestedBoolArrayValue = (value, no, name, type) => {
        const conf = this.state.confValue;
        conf[type][name][no] = value;
        this.setState({confValue: conf});
    };

    handleFileChange = (file, name, type) => {
        const conf = this.state.confValue;
        if (type !== null && type !== undefined) {
            conf[type][name] = file;
        } else {
            conf[name] = file;
        }
        this.setState({
            conf,
        });
    };

    handleEnableChange = (checked, name) => {
        const tempEnable = this.state.enable;
        tempEnable[name] = checked;
        this.setState({enable: tempEnable});
    };

    handleEnableChangeNested = (checked, name, type) => {
        const tempEnable = this.state.enable;
        if (type !== null && type !== undefined) {
            tempEnable[type][name] = checked;
        } else {
            tempEnable[name] = checked;
        }

        this.setState({enable: tempEnable});
    };

    flatEndCheck = (enable) => {
        const flatted = Object.values(enable).flat();
        let anyToModify = false;
        flatted.forEach((it) => {
            if (typeof it === "object") {
                if (this.flatEndCheck(it)) anyToModify = true;
            } else {
                if (it === true) anyToModify = true;
            }
        });

        return anyToModify;
    };

    handleEnableSave = () => {
        let anyToModify = this.flatEndCheck(this.state.enable);
        let anyError = this.flatEndCheck(this.state.confError);

        if (anyToModify && !anyError) {
            return "m-0 w-100";
        } else {
            return "m-0 w-100 disabled";
        }
    };

    isDisabledNewConfigurationAdd = () => {
        return (this.state.newConfigurationName === "")
    };

    handleEnableSaveDeleteTag = () => {
        if (this.state.selectedTags.length > 0) {
            return "m-0 w-100";
        } else {
            return "m-0 w-100 disabled";
        }
    };

    handleMigration = async () => {

        const devices = this.prepareDeviceListOfAnyType();
        if (devices.length === 0) return;

        let counter = 0;

        this.setState({
            showConfirmationModal: true, dataCountToModify: devices.length, dataCountModifyTillNow: counter,
        });

        for await (const device of devices) {
            try {
                const {data} = await AuthDeviceService.newEndpoint(device._id);

                if (data) this.setState({dataCountModifyTillNow: counter++});
            } catch (ex) {
                toast.error("Wystąpił nieoczekiwany problem !!!");
            }
        }

        window.location = "/authDevices";

        this.setState({showConfirmationModal: false});
    };

    handleDeleteMigration = async () => {

        const devices = this.prepareDeviceListOfAnyType();
        if (devices.length === 0) return;

        let counter = 0;

        this.setState({
            showConfirmationModal: true, dataCountToModify: devices.length, dataCountModifyTillNow: counter,
        });

        for await (const device of devices) {
            try {
                const {data} = await AuthDeviceService.deleteNewEndpoint(device._id);

                if (data) this.setState({dataCountModifyTillNow: counter++});
            } catch (ex) {
                toast.error("Wystąpił nieoczekiwany problem !!!");
            }
        }

        window.location = "/authDevices";

        this.setState({showConfirmationModal: false});
    };

    prepareOptions = () => {
        const {tags} = this.context;
        const {selectedTags} = this.state;

        let result = _.cloneDeep(tags);

        result = result.filter((tagFromAll) => {
            const exist = tagFromAll.dev_types.find((type) => type === this.context.selectedDevType?.type);
            if (exist) {
                return true;
            }
            return false;
        });

        if (selectedTags.length > 0) {
            result = result.filter((tagFilteredBySelectedDevice) => {
                const exist = selectedTags.every((x) => x !== tagFilteredBySelectedDevice._id);
                if (exist) return true; else return false;
            });
        }

        return result;
    };

    addTag = () => {
        const {selectedTag, selectedTags} = this.state;

        if (selectedTag === "") return false;

        const result = selectedTags.find((tag) => tag === selectedTag);

        if (result) return false;

        const _selectedTags = _.cloneDeep(selectedTags);
        console.log(_selectedTags);
        _selectedTags.push(selectedTag);

        this.setState({selectedTags: _selectedTags});
    };

    removeTag = (element) => {
        const {selectedTags} = this.state;
        const index = selectedTags.findIndex((tag) => tag === element);
        if (index === -1) return;
        selectedTags.splice(index, 1);
        this.setState({selectedTags});
    };


    getDeviceDefaultConfigurations = async () => {
        try {
            const {data} = await DeviceDefaultConfigurationService.getByType(this.context.selectedDevType?.type);
            if (data && data.length > 0) {
                this.setState({existingConfiguratons: data})
            }
        } catch (e) {
            toast.error("Wystąpił nieoczekiwany problem !!!");
        }
    }

    handleShowEditConfigurations = async (event) => {
        if (this.state.editDefaultConfigurations === false) {
            await this.getDeviceDefaultConfigurations();
            this.setState({selectedExistingConfiguration: ""})

        }
        this.setState({editDefaultConfigurations: !this.state.editDefaultConfigurations})
    }

    selectedDefaultConfiguration = (e) => {
        const _id = e.target.value;
        const index = this.state.existingConfiguratons.findIndex((x) => x._id === _id);

        if (index !== -1) {
            if (this.context.selectedDevType?.type === "MK") {

                let conf = this.state.existingConfiguratons[index].value.conf;
                let tags = this.state.existingConfiguratons[index].value.tags;

                let cashier = {};
                let cashierIndex = conf.distanceSensorTable.findIndex((x) => x.id === config.distanceSensorTypes.cashier_ID);
                if (cashierIndex !== -1) cashier = {
                    maxRange: conf.distanceSensorTable[cashierIndex].maxRange,
                    activeRange: conf.distanceSensorTable[cashierIndex].activeRange,
                    enable: conf.distanceSensorTable[cashierIndex].enable,
                    blockSensor: conf.distanceSensorTable[cashierIndex].blockSensor
                };

                let client = {};
                let clientIndex = conf.distanceSensorTable.findIndex((x) => x.id === config.distanceSensorTypes.client_ID);
                if (clientIndex !== -1) client = {
                    maxRange: conf.distanceSensorTable[clientIndex].maxRange,
                    activeRange: conf.distanceSensorTable[clientIndex].activeRange,
                    enable: conf.distanceSensorTable[clientIndex].enable,
                    blockSensor: conf.distanceSensorTable[clientIndex].blockSensor
                };

                const toSetConf = {
                    cdt: conf.cdt,
                    bdt: conf.bdt,
                    dt: conf.dt,
                    cashier: cashier,
                    client: client,
                    beepFile: [conf.beepTable[0].file, conf.beepTable[1].file, conf.beepTable[2].file, conf.beepTable[3].file, conf.beepTable[4].file, conf.beepTable[5].file],
                    beepDetectionFile: conf.beepTable[6].file
                }

                this.setState({confValue: toSetConf, selectedTags: tags})

            }
        }
        this.setState({selectedExistingConfiguration: _id})
    }

    handleChangeNewConfigurationName = (e) => {
        const name = e.target.value;
        if (name.length < 30) {
            this.setState({newConfigurationName: name})
        }
    }

    //Warunek pod jakim użytkownik może edytować konfiguracje
    enableEditConfigurations = () => {
        return (this.context.selectedDevType?.type === "MK")
    }

    isDisabledSubmitModifyExistingConfiguration = () => {
        return (this.state.selectedExistingConfiguration === "")
    }

    render() {
        const context = this.context;

        const {
            enable,
            confValue,
            confError,
            files,
        } = this.state;


        const {show, onHide} = this.props;
        const tagSelectorVisibility = (context.tagEdit && !context.migrationEdit) || (this.state.editDefaultConfigurations);
        // const shop = this.matchShop();


        return (
            <Modal
                show={show}
                size="xl"
                fullscreen={context.selectedDevType?.type !== "MKS" && !context.tagEdit && !context.migrationEdit ? true : false}
                onHide={() => {

                    onHide();
                }}
                onEnter={this.handleEnter}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton className="bg-light my-0 py-0">
                    <Row className="w-100 justify-content-center">
                        <Col className="col-auto">
                            <p className="display-6">
                                Wybranych urządzeń: <span className="text-success">{context.selectedCount}</span>
                            </p>
                        </Col>
                    </Row>
                </Modal.Header>
                <Modal.Body className="mx-0 px-0 justify-content-center">
                    {!context.tagEdit && !context.migrationEdit ? (<React.Fragment>
                        {context.selectedDevType?.type === "MA" ? (<React.Fragment>

                            <ManyGenerateTitle label="Playlista:" color="black">
                                <ManyGenerateIsActiveBlock
                                    name="playlistEnable"
                                    type=""
                                    value={confValue.playlistEnable}
                                    enable={enable.playlistEnable}
                                    label="Odtwarza playlistę:"
                                    onEnableChange={this.handleEnableChange}
                                    onValueChange={this.handlePlaylistValueChange}
                                />

                                <ManyGenerateIsActiveBlock
                                    name="playlistIMSEnable"
                                    type=""
                                    value={confValue.playlistIMSEnable}
                                    enable={enable.playlistIMSEnable}
                                    label="Odtwarza playlistę IMS:"
                                    onEnableChange={this.handleEnableChange}
                                    onValueChange={this.handleIMSPlaylistValueChange}
                                />
                            </ManyGenerateTitle>

                        </React.Fragment>) : null}
                        {context.selectedDevType?.type === "MK" ? (<React.Fragment>
                            <ManyGenerateTitle label="" color="white">
                                {[0, 1, 2, 3, 4, 5].map((x) => {
                                    return (<ManyGenerateFileChangeBlock
                                        name={x}
                                        type="beepFile"
                                        files={files}
                                        value={confValue.beepFile[x]}
                                        enable={enable.beepFile[x]}
                                        label={`Komunikat ${x + 1}:`}
                                        onEnableChange={this.handleEnableChangeNested}
                                        onFileChange={this.handleFileChange}
                                        key={"beepFile_" + x}
                                    />);
                                })}

                                <ManyGenerateFileChangeBlock
                                    name="beepDetectionFile"
                                    type={null}
                                    files={files}
                                    value={confValue.beepDetectionFile}
                                    enable={enable.beepDetectionFile}
                                    label={`Komunikat detekcji:`}
                                    onEnableChange={this.handleEnableChangeNested}
                                    onFileChange={this.handleFileChange}
                                    key={"beepDetectionFile_0"}
                                />
                            </ManyGenerateTitle>
                            <div className="row my-0 mx-auto">
                                <hr className="my-0 m-auto"></hr>
                            </div>
                            <ManyGenerateTitle label="CZUJNIK KASJERA:" color="black">
                                <ManyGenerateDistanceBlockMK
                                    name="maxRange"
                                    type="cashier"
                                    value={confValue.cashier.maxRange}
                                    enable={enable.cashier.maxRange}
                                    error={confError.cashier.maxRange}
                                    label="Minimalna odległość blokady:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleDistanceChange}
                                    min={0}
                                    max={400}
                                />

                                <ManyGenerateDistanceBlockMK
                                    name="activeRange"
                                    type="cashier"
                                    value={confValue.cashier.activeRange}
                                    enable={enable.cashier.activeRange}
                                    error={confError.cashier.activeRange}
                                    label="Odległość aktywna:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleDistanceChange}
                                    min={0}
                                    max={400}
                                />

                                <ManyGenerateBlockSensorBlockMK
                                    name="blockSensor"
                                    type="cashier"
                                    value={confValue.cashier.blockSensor}
                                    enable={enable.cashier.blockSensor}
                                    error={confError.cashier.blockSensor}
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleNestedBoolArrayValue}
                                />

                                <ManyGenerateIsActiveBlock
                                    name="enable"
                                    type="cashier"
                                    value={confValue.cashier.enable}
                                    enable={enable.cashier.enable}
                                    label="Aktywny:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleNestedBoolValue}
                                />
                            </ManyGenerateTitle>

                            <ManyGenerateTitle label="CZUJNIK KLIENT:" color="black">
                                <ManyGenerateDistanceBlockMK
                                    name="maxRange"
                                    type="client"
                                    value={confValue.client.maxRange}
                                    enable={enable.client.maxRange}
                                    error={confError.client.maxRange}
                                    label="Minimalna odległość blokady:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleDistanceChange}
                                    min={0}
                                    max={400}
                                />
                                <ManyGenerateDistanceBlockMK
                                    name="activeRange"
                                    type="client"
                                    value={confValue.client.activeRange}
                                    enable={enable.client.activeRange}
                                    error={confError.client.activeRange}
                                    label="Odległość aktywna:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleDistanceChange}
                                    min={0}
                                    max={400}
                                />

                                <ManyGenerateBlockSensorBlockMK
                                    name="blockSensor"
                                    type="client"
                                    value={confValue.client.blockSensor}
                                    enable={enable.client.blockSensor}
                                    error={confError.client.blockSensor}
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleNestedBoolArrayValue}
                                />

                                <ManyGenerateIsActiveBlock
                                    name="enable"
                                    type="client"
                                    value={confValue.client.enable}
                                    enable={enable.client.enable}
                                    label="Aktywny:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleNestedBoolValue}
                                />
                            </ManyGenerateTitle>

                            <ManyGenerateTitle label="" color="white">
                                <ManyGenerateTimeoutBlock
                                    name="dt"
                                    value={confValue.dt}
                                    enable={enable.dt}
                                    error={confError.dt}
                                    label="Minimalny czas przerwy pomiędzy detektowanymi zdarzeniami [s]:"
                                    min={10}
                                    max={10000}
                                    onEnableChange={this.handleEnableChange}
                                    onTimeoutChange={this.handleTimeoutChange}
                                />

                                <ManyGenerateTimeoutBlock
                                    name="bdt"
                                    value={confValue.bdt}
                                    enable={enable.bdt}
                                    error={confError.bdt}
                                    label="Czas detekcji zablokowanego czujnika kasjera/klienta [s]:"
                                    min={5}
                                    max={10000}
                                    onEnableChange={this.handleEnableChange}
                                    onTimeoutChange={this.handleTimeoutChange}
                                />

                                <ManyGenerateTimeoutBlock
                                    name="cdt"
                                    value={confValue.cdt}
                                    enable={enable.cdt}
                                    error={confError.cdt}
                                    label="Opóżnienienie reakcji na detekcję klienta [s]:"
                                    min={0}
                                    max={100}
                                    onEnableChange={this.handleEnableChange}
                                    onTimeoutChange={this.handleTimeoutChange}
                                />
                            </ManyGenerateTitle>
                        </React.Fragment>) : null}
                        {context.selectedDevType?.type === "MKS" ? (<React.Fragment>
                            {[0, 1, 2, 3, 4, 5].map((x) => {
                                return (<ManyGenerateFileChangeBlock
                                    name={x}
                                    type="beepFile"
                                    files={files}
                                    value={confValue.beepFile[x]}
                                    enable={enable.beepFile[x]}
                                    label={`Komunikat : ${x + 1}`}
                                    onEnableChange={this.handleEnableChangeNested}
                                    onFileChange={this.handleFileChange}
                                    key={"beepFile_" + x}
                                />);
                            })}
                            <div className="row my-0 mx-auto">
                                <hr className="my-0 m-auto"></hr>
                            </div>
                        </React.Fragment>) : null}
                        {context.selectedDevType?.type === "MS" ? (<React.Fragment>
                            <div className="row my-0 mx-auto">
                                <hr className="my-0 m-auto"></hr>
                            </div>
                            <ManyGenerateTitle label="" color="white">
                                <ManyGenerateFileChangeBlock
                                    name="beepDetectionFile"
                                    type={null}
                                    files={files}
                                    value={confValue.beepDetectionFile}
                                    enable={enable.beepDetectionFile}
                                    label={`Komunikat detekcji:`}
                                    onEnableChange={this.handleEnableChangeNested}
                                    onFileChange={this.handleFileChange}
                                    key={"beepDetectionFile_0"}
                                />
                            </ManyGenerateTitle>
                            <ManyGenerateTitle label="CZUJNIK SUFITOWY:" color="black">
                                <ManyGenerateDistanceBlock
                                    name="activeRange"
                                    type="ceiling"
                                    value={confValue.ceiling.activeRange}
                                    enable={enable.ceiling.activeRange}
                                    error={confError.ceiling.activeRange}
                                    label="Odległość aktywna:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleDistanceChangeMS}
                                    min={0}
                                    max={400}
                                />
                                <ManyGenerateIsActiveBlock
                                    name="enable"
                                    type="ceiling"
                                    value={confValue.ceiling.enable}
                                    enable={enable.ceiling.enable}
                                    label="Aktywny:"
                                    onEnableChange={this.handleEnableChangeNested}
                                    onValueChange={this.handleNestedBoolValue}
                                />
                            </ManyGenerateTitle>
                            <ManyGenerateTitle label="" color="white">
                                <ManyGenerateTimeoutBlock
                                    name="dt"
                                    value={confValue.dt}
                                    enable={enable.dt}
                                    error={confError.dt}
                                    label="Minimalny czas przerwy pomiędzy detektowanymi zdarzeniami [s]:"
                                    min={10}
                                    max={10000}
                                    onEnableChange={this.handleEnableChange}
                                    onTimeoutChange={this.handleTimeoutChange}
                                />
                            </ManyGenerateTitle>
                        </React.Fragment>) : null}
                    </React.Fragment>) : null}

                    {tagSelectorVisibility ? (
                        <React.Fragment>
                            <Row className={"w-100 mx-0 px-0 row_visible"} style={{
                                // visibility: this.state.createNewDefaultConfig ? "visible" : "hidden",
                                // transition: "all  2s"
                            }}>
                                <Col
                                    xs="12"
                                    md="2"
                                    className="d-flex justify-content-center "
                                    style={{
                                        minWidth: "100px",
                                    }}
                                ></Col>
                                <Col xs="12"
                                     md="10"
                                     className="justify-content-center px-0 my-1">
                                    <div className="tag-container" style={{justifyContent: "center"}}>
                                        <div className="mx-2 my-auto fw-bold h-100">Wybrane TAGI:</div>
                                        {this.state.selectedTags.map((tag) => {
                                            const result = this.context.tags.find((tagFromAll) => tagFromAll._id === tag);
                                            if (!result) return null;
                                            return (<div className="tag" key={result._id}>
                                                <div className="tag__name">{result.name}</div>
                                                {/* {this.state.tagEdit && ( */}
                                                <FontAwesomeIcon
                                                    onClick={() => this.removeTag(result._id)}
                                                    className="tag__delete"
                                                    icon={faTimes}
                                                />
                                                {/* )} */}
                                            </div>);
                                        })}
                                        {/* <FontAwesomeIcon
                  onClick={() => this.setState({ tagEdit: !this.state.tagEdit })}
                  className="tag__edit"
                  icon={faEdit}
                /> */}
                                    </div>
                                </Col>
                            </Row>
                            <Row className={"w-100 mx-0 px-0"}>
                                <Col
                                    xs="12"
                                    md="2"
                                    className="d-flex justify-content-center "
                                    style={{
                                        minWidth: "100px",
                                    }}
                                ></Col>
                                <Col xs="12"
                                     md="10"
                                     className="justify-content-center px-0">
                                    {this.context.tags.length ? (
                                        <div className="tag-container flex-container__center w-100 mb-1">
                                            <SimpleSelect
                                                style={{width: "200px"}}
                                                value={this.state.selectedTag}
                                                optionName="name"
                                                optionKey="_id"
                                                options={this.prepareOptions()}
                                                onChange={(e) => this.setState({selectedTag: e.target.value})}
                                            />

                                            <FontAwesomeIcon
                                                onClick={this.addTag}
                                                className="tag__add ms-1"
                                                size="2x"
                                                icon={faPlusSquare}
                                            />
                                        </div>) : null}
                                </Col></Row>
                        </React.Fragment>
                    ) : null}
                    {(context.tagEdit && !context.migrationEdit) ? (<React.Fragment>
                        <Row className="justify-content-center m-auto" style={{width: "90%"}}>
                            <Col className="col-4 mt-2">
                                <ConfirmationButton
                                    label={dictionary.authDevices.modifyModal.removeAllTagButton_confirmLabel}
                                    yesLabel={dictionary.authDevices.modifyModal.removeAllTagButton_confirmYesLabel}
                                    noLabel={dictionary.authDevices.modifyModal.removeAllTagButton_confirmNoLabel}
                                    variant="danger"
                                    className="m-0 w-100"
                                    size="sm"
                                    onClick={(e) => this.removeAllTags()}
                                >
                                    {dictionary.authDevices.modifyModal.removeAllTagButton_label}
                                </ConfirmationButton>
                            </Col>
                            <Col className="col-4 mt-2">
                                <ConfirmationButton
                                    label={dictionary.authDevices.modifyModal.removeTagButton_confirmLabel}
                                    yesLabel={dictionary.authDevices.modifyModal.removeTagButton_confirmYesLabel}
                                    noLabel={dictionary.authDevices.modifyModal.removeTagButton_confirmNoLabel}
                                    variant="warning"
                                    className={this.handleEnableSaveDeleteTag()}
                                    size="sm"
                                    onClick={(e) => this.removeTags()}
                                >
                                    {dictionary.authDevices.modifyModal.removeTagButton_label}
                                </ConfirmationButton>
                            </Col>
                            <Col className="col-4 mt-2">
                                <ConfirmationButton
                                    label={dictionary.authDevices.modifyModal.addTagButton_confirmLabel}
                                    yesLabel={dictionary.authDevices.modifyModal.addTagButton_confirmYesLabel}
                                    noLabel={dictionary.authDevices.modifyModal.addTagButton_confirmNoLabel}
                                    variant="primary"
                                    className={this.handleEnableSaveDeleteTag()}
                                    size="sm"
                                    onClick={(e) => this.saveTags()}
                                >
                                    {dictionary.authDevices.modifyModal.addTagButton_label}
                                </ConfirmationButton>
                            </Col>
                        </Row>
                    </React.Fragment>) : null}


                    {!context.tagEdit && !context.migrationEdit ? (<Row className={"w-100 mx-0 px-0"}>
                        <Col
                            xs="12"
                            md="2"
                            className="d-flex justify-content-center "
                            style={{
                                minWidth: "100px",
                            }}
                        ></Col>
                        <Col
                            xs="12"
                            md="10"
                            className="justify-content-center px-0"
                            style={{
                                minWidth: "100px",
                            }}
                        >
                            <Row className="justify-content-center m-auto" style={{width: "90%"}}>
                                {this.enableEditConfigurations() ?
                                    <Col className="col-4 mt-2">
                                        {/*<ConfirmationButton*/}
                                        {/*    label={dictionary.authDevices.modifyModal.saveButton_confirmLabel}*/}
                                        {/*    yesLabel={dictionary.authDevices.modifyModal.saveButton_confirmYesLabel}*/}
                                        {/*    noLabel={dictionary.authDevices.modifyModal.saveButton_confirmNoLabel}*/}
                                        {/*    variant="primary"*/}
                                        {/*    className={this.handleEnableSave()}*/}
                                        {/*    size="sm"*/}
                                        {/*    onClick={(e) => this.doSubmit()}*/}
                                        {/*>*/}
                                        {/*    {dictionary.authDevices.modifyModal.saveButton_label}*/}
                                        {/*</ConfirmationButton>*/}
                                        <Button
                                            onClick={this.handleShowEditConfigurations}
                                            size="sm"
                                            variant="secondary"
                                            className="m-0 w-100">
                                            Edycja dostępnych konfiguracji
                                            <FontAwesomeIcon
                                                style={{marginLeft: "1.5rem"}}
                                                icon={this.state.editDefaultConfigurations ? faCaretUp : faCaretDown}
                                            />
                                        </Button>
                                    </Col> : null
                                }
                                <Col className="col-4 mt-2">
                                    <ConfirmationButton
                                        label={dictionary.authDevices.modifyModal.saveButton_confirmLabel}
                                        yesLabel={dictionary.authDevices.modifyModal.saveButton_confirmYesLabel}
                                        noLabel={dictionary.authDevices.modifyModal.saveButton_confirmNoLabel}
                                        variant="primary"
                                        className={this.handleEnableSave()}
                                        size="sm"
                                        onClick={(e) => this.handleSubmit()}
                                    >
                                        {dictionary.authDevices.modifyModal.saveButton_label}
                                    </ConfirmationButton>
                                    {/* <Button onClick={this.doSubmit} variant="primary" className="m-0 w-100">
                                                Zapisz
                                              </Button> */}
                                </Col>

                            </Row>
                        </Col>
                    </Row>) : null}

                    {this.state.editDefaultConfigurations ? (
                        <React.Fragment>
                            <Row className={"w-100 mx-0 mt-2 px-0 row_visible"} style={{
                                // visibility: this.state.createNewDefaultConfig ? "visible" : "hidden",
                                // transition: "all  2s"
                            }}>
                                <Col
                                    xs="12"
                                    md="2"
                                    className="d-flex justify-content-center "
                                    style={{
                                        minWidth: "100px",
                                    }}
                                ></Col>
                                <Col xs="12"
                                     md="3"
                                     className="d-flex justify-content-end align-items-center px-0 pe-2"
                                >
                                    <p style={{
                                        fontWeight: "bold",
                                        textAlign: "right",
                                        margin: 0,
                                        color: (this.isDisabledSubmitModifyExistingConfiguration()) ? "#DB222A" : "black"
                                    }}>Dostępne
                                        konfiguracje:</p>
                                </Col>

                                <Col xs="12"
                                     md="3"
                                     className=" d-flex justify-content-center  align-items-center px-0"
                                     style={{alignItems: "center"}}>
                                    {/*// <div className="tag-container flex-container__center w-100 mb-3">*/}
                                    <SimpleSelect
                                        style={{width: "100%"}}
                                        value={this.state.selectedExistingConfiguration}
                                        optionName="name"
                                        optionKey="_id"
                                        options={this.state.existingConfiguratons}
                                        onChange={this.selectedDefaultConfiguration}
                                    />

                                    {/*// </div>) : null}*/}
                                </Col>
                                <Col xs="12"
                                     md="3" className=" d-flex  align-items-center px-0 ps-3">
                                    <ConfirmationButton
                                        label={dictionary.authDevices.modifyModal.modifyConfButton_confirmLabel}
                                        yesLabel={dictionary.authDevices.modifyModal.modifyConfButton_confirmYesLabel}
                                        noLabel={dictionary.authDevices.modifyModal.modifyConfButton_confirmNoLabel}
                                        variant="success"
                                        size="sm"
                                        style={{marginLeft: "0.5rem", marginRight: "0.5rem", width: "50%"}}
                                        onClick={(e) => this.handleSubmitDefaultConfiguration(this.state.selectedExistingConfiguration)}
                                        disabled={this.isDisabledSubmitModifyExistingConfiguration()}
                                    >
                                        {dictionary.authDevices.modifyModal.modifyConfButton_label}
                                    </ConfirmationButton>
                                    {/* <Button onClick={this.doSubmit} variant="primary" className="m-0 w-100">
                                                Zapisz
                                              </Button> */}
                                    <ConfirmationButton
                                        label={dictionary.authDevices.modifyModal.deleteConfButton_confirmLabel + " " + this.state.existingConfiguratons.find((conf) => conf._id === this.state.selectedExistingConfiguration)?.name + "?"}
                                        yesLabel={dictionary.authDevices.modifyModal.deleteConfButton_confirmYesLabel}
                                        noLabel={dictionary.authDevices.modifyModal.deleteConfButton_confirmNoLabel}
                                        variant="danger"
                                        size="sm"
                                        style={{marginLeft: "0.5rem", marginRight: "0.5rem", width: "50%"}}
                                        onClick={(e) => this.handleDeleteDefaultConfiguration(this.state.selectedExistingConfiguration)}
                                        disabled={this.isDisabledSubmitModifyExistingConfiguration()}
                                    >
                                        {dictionary.authDevices.modifyModal.deleteConfButton_label}
                                    </ConfirmationButton>
                                </Col>

                            </Row>
                        </React.Fragment>
                    ) : null}

                    {this.state.editDefaultConfigurations ? (
                        <React.Fragment>
                            <Row className={"w-100 mx-0 mt-2 px-0 row_visible"} style={{
                                // visibility: this.state.createNewDefaultConfig ? "visible" : "hidden",
                                // transition: "all  2s"
                            }}>
                                <Col
                                    xs="12"
                                    md="2"
                                    className="d-flex justify-content-center "
                                    style={{
                                        minWidth: "100px",
                                    }}
                                ></Col>
                                <Col xs="12"
                                     md="3"
                                     className="d-flex justify-content-end align-items-center px-0 pe-2"
                                >
                                    <p style={{
                                        fontWeight: "bold",
                                        textAlign: "right",
                                        margin: 0,
                                        color: (this.isDisabledNewConfigurationAdd()) ? "#DB222A" : "black"
                                    }}>Nazwa konfiguracji:</p>
                                </Col>

                                <Col xs="12"
                                     md="3"
                                     className=" d-flex justify-content-center  align-items-center px-0"
                                     style={{alignItems: "center"}}>
                                    {/*// <div className="tag-container flex-container__center w-100 mb-3">*/}
                                    <input
                                        style={{width: "100%"}}
                                        value={this.state.newConfigurationName}

                                        onChange={this.handleChangeNewConfigurationName}
                                    />

                                    {/*// </div>) : null}*/}
                                </Col>
                                <Col xs="12"
                                     md="3" className=" d-flex justify-content-start  align-items-center px-0 ps-3">
                                    <ConfirmationButton
                                        label={dictionary.authDevices.modifyModal.newConfButton_confirmLabel}
                                        yesLabel={dictionary.authDevices.modifyModal.newConfButton_confirmYesLabel}
                                        noLabel={dictionary.authDevices.modifyModal.newConfButton_confirmNoLabel}
                                        variant="success"
                                        size="sm"
                                        style={{margin: "0.5rem", width: "100%"}}
                                        onClick={(e) => this.handleSubmitDefaultConfiguration(null)}
                                        disabled={this.isDisabledNewConfigurationAdd()}
                                    >
                                        {dictionary.authDevices.modifyModal.newConfButton_label}
                                    </ConfirmationButton>
                                    {/* <Button onClick={this.doSubmit} variant="primary" className="m-0 w-100">
                                                Zapisz
                                              </Button> */}
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}


                    {!context.tagEdit && context.migrationEdit ? (<React.Fragment>
                        <div className="tag-container flex-container__center m-3">
                            <p className="fw-bold">
                                Czy na pewno zmienić adres API dla wszystkich wybranych urządzeń?
                            </p>{" "}
                            Zmiana będzie nie odwracalna. Urządzenia po poprawnym nawiązaniu połączenia z
                            nowym
                            serwerem zerwą połączenie z bieżącym.
                        </div>
                        <Row className="justify-content-center m-auto" style={{width: "90%"}}>
                            <Col className="col-4 mt-2">
                                <ConfirmationButton
                                    label={dictionary.authDevices.modifyModal.migrationConfigmButton_confirmLabel}
                                    yesLabel={dictionary.authDevices.modifyModal.migrationConfigmButton_confirmYesLabel}
                                    noLabel={dictionary.authDevices.modifyModal.migrationConfigmButton_confirmNoLabel}
                                    variant="primary"
                                    className="m-0 w-100"
                                    size="sm"
                                    onClick={(e) => this.handleMigration()}
                                >
                                    {dictionary.authDevices.modifyModal.migrationConfigmButton_label}
                                </ConfirmationButton>
                            </Col>
                        </Row>
                        <Row className="justify-content-center m-auto" style={{width: "90%"}}>
                            <Col className="col-4 mt-2">
                                <ConfirmationButton
                                    label={dictionary.authDevices.modifyModal.deleteMigrationConfigmButton_confirmLabel}
                                    yesLabel={dictionary.authDevices.modifyModal.deleteMigrationConfigmButton_confirmYesLabel}
                                    noLabel={dictionary.authDevices.modifyModal.deleteMigrationConfigmButton_confirmNoLabel}
                                    variant="primary"
                                    className="m-0 w-100"
                                    size="sm"
                                    onClick={(e) => this.handleDeleteMigration()}
                                >
                                    {dictionary.authDevices.modifyModal.deleteMigrationConfigmButton_label}
                                </ConfirmationButton>
                            </Col>
                        </Row>
                    </React.Fragment>) : null}
                </Modal.Body>
                <ProgressModal
                    show={this.state.showConfirmationModal}
                    actual={this.state.dataCountModifyTillNow}
                    max={this.state.dataCountToModify}
                />
            </Modal>
        );
    }


}

export default AuthDevicesModifyModal;
