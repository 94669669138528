import React from "react";
import Joi from "joi";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import UForm from "./common/uform";
import SearchBox from "./common/searchBox";

import * as AuthDeviceService from "../services/authDeviceService";
import UnauthDeviceService from "../services/unauthDeviceService";
import ShopService from "../services/shopService";
import {toast} from "react-toastify";

import {prepareDate} from "../utils/dateFormat";
import {getName as getNameOfDevType} from "../utils/devType"
import * as DeviceDefaultConfigurationService from "../services/deviceDefaultConfigurationService";


class UnauthDeviceAddFormModal extends UForm {
    state = {
        data: {
            info: "1",
            internalId: 1,
            shopId: "",
            defaultConfigurationId: "",
        },
        shops: [],
        errors: {},
        filteredShops: [],
        searchQuery: "",
        defaultConfiguratons: [],
    };


    // schema = Joi.object({
    //   info: Joi.string().allow(null).allow('').min(0).max(100).optional().label("Info"),
    //   internalId: Joi.number()
    //     .min(1)
    //     .max(100)
    //     .required()
    //     .label("Wewnętrzne ID")
    //     .optional(),
    //   shopId: Joi.string().min(5).max(100).optional().label("shopId"),
    // });

    componentDidMount = async () => {
        const shops = [{_id: "none", name: "Wybierz drogerię"}, ...this.props.shops];
        const data = this.state.data;
        data.shopId = "none";


        this.setState({data, shops, filteredShops: shops});
    };

    handleEnter = async () => {

        let defaultConfiguratons = [{_id: "", name: "Wybierz konfigurację"}];
        try {
            const {data} = await DeviceDefaultConfigurationService.getByType(this.props.unauthDevice.dev_type);
            if (data.length > 0)
                defaultConfiguratons = [...defaultConfiguratons, ...data];

            this.setState({defaultConfiguratons})
        } catch (e) {
            this.setState({defaultConfiguratons})
        }


    }

    doSubmit = async () => {
        const {unauthDevice: device} = this.props;
        const {data, shops} = this.state;

        // const shop = this.matchShop();
        const shop = shops.filter((shop) => shop._id === data.shopId);

        console.log(shop);
        if (!shop[0]._id) {
            toast.error("Nie ma takiej drogerii !!!");
            return;
        }

        try {
            const {data: authDevice} = await AuthDeviceService.add({
                sn: device.sn,
                dev_type: device.dev_type,
                last_seen: device.last_seen,
                fw_version: device.fw_version,
                hw_version: device.hw_version,
                ssid: device.ssid,
                shop_id: shop[0]._id,
                info: data.info,
                internal_id: data.internalId,
            });

            if (!authDevice) {
                toast.error("Urządzenie nie zostało dodane!!");
                return;
            }

            if (this.props.unauthDevice.dev_type === "MK") {
                if (data.defaultConfigurationId !== "") {
                    const defaultConfiguration = this.state.defaultConfiguratons.filter((conf) => conf._id === data.defaultConfigurationId);
                    if (defaultConfiguration.length !== 0) {
                        console.log({_id: authDevice._id, conf: defaultConfiguration[0].conf})
                        let result = await AuthDeviceService.postConf({
                            _id: authDevice._id,
                            conf: defaultConfiguration[0].value.conf
                        });

                        if (result) {
                            result = await AuthDeviceService.updateTags({
                                _id: authDevice._id,
                                tags: defaultConfiguration[0].value.tags
                            });

                            if (result) {
                                toast.success("Urządzenie zostało dodane i skonfigurowane !!!");
                            }
                        }
                    }


                }

            } else {
                toast.success("Urządzenie zostało dodane !!!");
            }

        } catch (ex) {
            toast.error("Błąd !!");
            return;
        }

        try {
            this.props.onDelete(null, device);
            this.props.onHide();
        } catch (ex) {
            toast.error("Błąd !!");
            return;
        }

        // const _id = this.props.match.params.id;
        // try {
        //   const { data } = this.state;
        //   if (!_id || _id === "new") {
        //     const { data: newShop } = await Shop.add(data);
        //     if (newShop) {
        //       toast.success("Nowa drogeria została dodana!");
        //     }
        //     return;
        //   }
        //   const { data: updatedShop } = await Shop.update(_id, data);
        //   if (updatedShop) {
        //     toast.success("Dane zostały zmienione!");
        //   }
        //   console.log("poprawiony");
        //   return;
        // } catch (ex) {
        //   console.log(ex);
        //   if (ex.response && ex.response.status === 400) {
        //     const errors = { ...this.state.errors };
        //     this.setState({ errors });
        //   }
        // }
    };

    handleSearch = (query) => {
        console.log(query);
        const shops = this.filterShopsList(query);

        this.setState({filteredShops: shops, searchQuery: query});
    };

    filterShopsList = (query) => {
        const {shops: allShops} = this.state;

        if (query) {
            console.log(query);
            const shops = allShops.filter((shop) =>
                shop.name.toLowerCase().startsWith(query.toLowerCase())
            );

            const data = this.state.data;
            if (shops.length > 0) {
                data.shopId = shops[0]._id;
            } else {
                data.shopId = "";
            }
            this.setState({data});
            return shops;
        }

        return allShops;
    };


    // matchShop = () => {
    //   const {unauthDevice, shops} = this.props;
    //   let shopSelected = {};
    //   shops.map((shop) => {
    //     console.log(unauthDevice);
    //     if (unauthDevice && (unauthDevice.ssid === shop.ssid)) shopSelected = shop;
    //     return 0;
    //   });

    //   return shopSelected;
    // };


    render() {
        //const { searchQuery, filteredShops: shops } = this.state;

        const {unauthDevice} = this.props;
        const {filteredShops, searchQuery} = this.state;
        const {show, onHide} = this.props;

        // const shop = this.matchShop();
        if (unauthDevice !== null && (unauthDevice.dev_type === "MK")) {
            this.schema = Joi.object({
                info: Joi.number().allow(null).allow(0).min(0).max(99).optional().label("Info"),
                internalId: Joi.number()
                    .min(1)
                    .max(100)
                    .required()
                    .label("Wewnętrzne ID")
                    .optional(),
                shopId: Joi.string().min(5).max(100).optional().label("shopId").messages({
                    "string.empty": `Drogeria musi być wybrana`,
                    "string.min": `Drogeria musi być wybrana`,
                    "any.required": `Drogeria musi być wybrana`,
                }),
                defaultConfigurationId: Joi.string().optional().messages({
                    "string.empty": `Konfiguracja musi być wybrana`,
                    "string.min": `Konfiguracja musi być wybrana`,
                    "any.required": `Konfiguracja musi być wybrana`
                }),

            });
        } else if (unauthDevice !== null && unauthDevice.dev_type === "MKS") {
            this.schema = Joi.object({
                info: Joi.number().allow(null).allow(0).min(0).max(99).optional().label("Info"),
                internalId: Joi.number()
                    .min(1)
                    .max(100)
                    .required()
                    .label("Wewnętrzne ID")
                    .optional(),
                shopId: Joi.string().min(5).max(100).optional().label("shopId").messages({
                    "string.empty": `Drogeria musi być wybrana`,
                    "string.min": `Drogeria musi być wybrana`,
                    "any.required": `Drogeria musi być wybrana`,
                }),
                defaultConfigurationId: Joi.string().allow(null).empty('').optional(),
            });
        } else {
            this.schema = Joi.object({
                info: Joi.string().allow(null).allow('').min(0).max(100).optional().label("Info"),
                internalId: Joi.number()
                    .min(1)
                    .max(100)
                    .required()
                    .label("Wewnętrzne ID")
                    .optional(),
                shopId: Joi.string().min(5).max(100).optional().label("shopId").messages({
                    "string.empty": `Drogeria musi być wybrana`,
                    "string.min": `Drogeria musi być wybrana`,
                    "any.required": `Drogeria musi być wybrana`,
                }),
                defaultConfigurationId: Joi.string().allow(null).empty('').optional(),

            });
        }

        return (
            <Modal
                show={show}
                onEnter={this.handleEnter}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Form onSubmit={this.handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Dodawanie urządzenia do systemu
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="show-grid">
                        {unauthDevice !== null && (
                            <React.Fragment>
                                <Row>
                                    <Col md="6" xs="6" className="d-flex">
                                        <p className="fw-bold">SN: </p>{" "}
                                        <p className={"ms-2"}>{unauthDevice.sn}</p>
                                    </Col>
                                    <Col md="6" xs="6" className="d-flex">
                                        <p className="fw-bold">Typ: </p>{" "}
                                        <p className={"ms-2"}>{getNameOfDevType(unauthDevice.dev_type)}</p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="6" xs="6" className="d-flex">
                                        <p className="fw-bold">SSID: </p>{" "}
                                        <p className={"ms-2"}>{unauthDevice.ssid} </p>
                                    </Col>
                                    {/* {(shop && shop.name) &&
                  (<Col md="6" xs="6" className="d-flex">
                    <p className="font-weight-bold mr-2">Nr. drogerii: </p>{" "}
                    {shop.name}
                  </Col>)}

                  {(!shop.name) &&
                    (<Col md="6" xs="6" className="d-flex">
                      <p className="font-weight-bold mr-2" style={{color: "red"}}>Drogeria: </p>{" "}
                     
                    </Col>)} */}

                                </Row>
                                <Row>
                                    <Col md="6" xs="6" className="d-flex">
                                        <p className="font-weight-bold mr-2">
                                            {prepareDate(unauthDevice.last_seen)}
                                        </p>
                                    </Col>
                                    <Col md="6" xs="6" className="d-flex">
                                        <p className="font-weight-bold mr-2"></p>{" "}
                                    </Col>
                                </Row>
                                <br></br>
                            </React.Fragment>
                        )}
                        <Row>
                            <Col md="6" xs="6">
                                {this.renderSelect("shopId", "Drogeria", filteredShops)}
                            </Col>
                            <Col md="6" xs="6">
                                <SearchBox
                                    value={searchQuery}
                                    onChange={this.handleSearch}
                                    label="Wyszukaj:"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12" xs="12">
                                {unauthDevice !== null && (unauthDevice.dev_type === "MK" || unauthDevice.dev_type === "MKS") &&
                                    this.renderNumberInput("info", "Opis", "number", 0, 99)}

                                {unauthDevice !== null && unauthDevice.dev_type !== "MK" && unauthDevice.dev_type !== "MKS" &&
                                    this.renderInput("info", "Opis")}

                            </Col>
                        </Row>
                        {/*<Row>*/}
                        {/*    <Col md="12" xs="12">*/}
                        {/*        {this.renderInput("internalId", "Wewnętrzne ID", "number")}*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        {unauthDevice && unauthDevice.dev_type === "MK" &&
                            <Row className={"mt-3"}>
                                <Col md="12" xs="12">
                                    {this.renderSelect("defaultConfigurationId", "Konfiguracja", this.state.defaultConfiguratons)}
                                </Col>
                            </Row>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={onHide} variant="primary">
                            Zostaw w poczekalni
                        </Button>
                        {this.renderButton(" Dodaj urządzenie")}
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

export default UnauthDeviceAddFormModal;
