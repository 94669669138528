import React, { Component } from "react";
import Button from "react-bootstrap/Button";

import {isAdmin} from "../services/authService";
import UTable from "./common/table";

class ShopsTable extends Component {
  columns = [
    {
      path: "name",
      label: "Nr. drogerii",
    },
    {
      path: "city",
      label: "Miasto",
    },
    {
      path: "adress",
      label: "Adres",
    },
    {
      path: "postCode",
      label: "Kod pocztowy",
    }
  ];

editColumn = {
  path: "",
  key: "edit",
  content: (item) => (
    <Button 
      variant="warning"
      className="my-0"
      size="sm"
      onClick={(e) => this.props.onEdit(e, item)}
    >
      Edytuj
    </Button>
  ),
}

constructor() {
  super();
  // if(isAdmin()){
    this.columns.push(this.editColumn)
  // }
}

  render() {
    const { shops, sortColumn, onSort, onRowClick } = this.props;

    return (
      <UTable
        columns={this.columns}
        onSort={onSort}
        sortColumn={sortColumn}
        data={shops}
        onRowClick={onRowClick}
        dataId="_id"
      />
    );
  }
}

export default ShopsTable;
