import Joi from "joi";

export const schema = Joi.object({
    name: Joi.string().min(4).max(4).required().optional().label("Nr. drogerii"),
    city: Joi.string()
      .min(0)
      .max(50)
      .optional()
      .label("Miasto"),
    adress: Joi.string()
      .min(0)
      .max(100)
      .optional()
      .label("Adres"),
    postCode: Joi.string()
        .min(0)
        .max(100)
        .optional()
        .label("Kod pocztowy"),
    pnOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    pnClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
    wtOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    wtClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
    srOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    srClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
    czwOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    czwClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
    ptOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    ptClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
    sbOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    sbClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
    ndOpen: Joi.string()
      .min(1)
      .max(5)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina otwarcia"),
    ndClose: Joi.string()
      .min(1)
      .max(10)
      .regex(/^([0-9]{2}):([0-9]{2})$/)
      .required()
      .optional()
      .label("Godzina zamknięcia"),
  });