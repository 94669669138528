import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

import { toast } from "react-toastify";

import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Accordion from "react-bootstrap/Accordion";
import Shop from "../services/shopService";
import WifiService from "../services/wifiService";

import { isAdmin } from "../services/authService";
import ConfirmationModalContextProvider from "./common/modalConfirmationContext";



import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Pagination from "./common/pagination";
import { searchInShopTable } from "../utils/search";
import { paginate } from "../utils/paginate";
import AddWifi from "./common/addWifi";

import WifiTable from "./wifiTable";

import _ from "lodash";
import SearchBox from "./common/searchBox";
import {findShopsWithDevices} from "../utils/select";

const containerStyle = {
  width: "100%",
  height: "400px",
  margin: "auto",
};

class WifiSettings extends Component {
  state = {
    shopsWithWifi: [],
    allWifi: [],
    pageSize: 100,
    currentPage: 1,
    sortColumn: { path: "name", order: "asc" },
    searchQuery: "",
    inUse: true,
    settingsMode: false,
  };

  async componentDidMount() {
    const shops = await this.getShopData(this.state.inUse);
    await this.updateWifiInShops(shops);
    this.setState({ shopsWithWifi: shops });
  }

  addToShop = (shop, wifi) => {
    if (shop?.wifis) {
      shop.wifis.push({
        all: wifi.all,
        ssid: wifi.ssid,
        pass: wifi.pass,
        addedAt: wifi.addedAt,
        _id: wifi._id,
      });
    } else {
      shop.wifis = [
        {
          all: wifi.all,
          ssid: wifi.ssid,
          pass: wifi.pass,
          addedAt: wifi.addedAt,
          _id: wifi._id,
        },
      ];
    }
  };

  updateWifiInShops = async (shops) => {
    try {
      const { data: allWifi } = await WifiService.getAll();

      allWifi.forEach((wifi) => {
        if (wifi.all === true) {
          shops.forEach((shop) => {
            this.addToShop(shop, wifi);
          });
        } else {
          wifi.shops.forEach((shopId) => {
            const result = shops.find((shop) => shop._id === shopId);
            if (result) {
              this.addToShop(result, wifi);
            }
          });
        }
      });

      this.setState({ allWifi });
    } catch (err) {
      // toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  };

  getShopData = async (inUse) => {
    try {
      if (inUse) {
        const {data} = await Shop.getAllShopsAndDevices();
        const shops = findShopsWithDevices(data.allShops, data.devices);

        return shops;
      } else {
        const { data: shops } = await Shop.getAll();
        return shops;
      }
    } catch (err) {
      // toast.error("Błąd serwera!!!");
      this.props.history.push("/not-found");
    }
  };

  handleRefreshWifiData = async () => {
    const { shopsWithWifi } = this.state;

    shopsWithWifi.forEach((shop) => {
      if (shop?.wifis) shop.wifis.length = 0;
    });
    await this.updateWifiInShops(shopsWithWifi);
    this.setState({ shopsWithWifi });
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  handleSort = (sortColumn) => {
    if (sortColumn.path === "") return;

    this.setState({ sortColumn });
  };

  getPagedData = () => {
    const {
      pageSize,
      currentPage,
      shopsWithWifi: allShops,
      sortColumn,
      searchQuery,
    } = this.state;

    let filtered = allShops;

    if (searchQuery) {
      filtered = searchInShopTable(
        allShops,
        ["name", "city", "adress"],
        searchQuery
      );
    }

    const sorted = _.orderBy(filtered, [sortColumn.path], [sortColumn.order]);

    const shops = paginate(sorted, currentPage, pageSize);

    return { totalCount: filtered.length, data: shops };
  };

  handleSearch = (query) => {
    this.setState({
      searchQuery: query,
      currentPage: 1,
    });
  };

  handleInUseChange = async ({ target }) => {
    this.setState({
      inUse: target.checked,
    });

    const shops = await this.getShopData(target.checked);
    await this.updateWifiInShops(shops);
    this.setState({ shopsWithWifi: shops });
  };

  render() {
    if (!isAdmin()) return <Redirect to="/shops" />;
    const count = this.state.shopsWithWifi.length;
    const {
      pageSize,
      currentPage,
      sortColumn,
      searchQuery,
      user,
      shopsWithWifi: allShops,
      settingsMode,
    } = this.state;

    // if (count === 0) {
    //   return (
    //     <React.Fragment>
    //       <p>Nie ma urządzeń oczekujących na akceptację!!!</p>
    //     </React.Fragment>
    //   );
    // }

    const { totalCount, data: shops } = this.getPagedData();

    return (
      <React.Fragment>
        <Breadcrumb style={{ backgroundColor: "#DDDDDD" }}>
          <Breadcrumb.Item
            style={{
              marginTop: 3,
              marginLeft: 3,
              marginBottom: 3,
              marginRight: 0,
            }}
            href="/"
          >
            Home
          </Breadcrumb.Item>
          <Breadcrumb.Item
            style={{
              marginTop: 3,
              marginLeft: 3,
              marginBottom: 3,
              marginRight: 0,
            }}
            active
          >
            Konfiguracja
          </Breadcrumb.Item>
          <Breadcrumb.Item
            style={{
              marginTop: 3,
              marginLeft: 3,
              marginBottom: 3,
              marginRight: 0,
            }}
            active
          >
            Zarządzanie sieciami WiFi
          </Breadcrumb.Item>
        </Breadcrumb>
        <ConfirmationModalContextProvider>
          <Container fluid>
            <Row sm={12} className="pt-2 flex-container__center ">
              <Col sm="4" className="pt-2" style={{ textAlign: "center" }}>
                <label>
                  Drogerie tylko z urządzeniami:
                  <input
                    className="m-2"
                    name="inUse"
                    type="checkbox"
                    checked={this.state.inUse}
                    onChange={this.handleInUseChange}
                  />
                </label>
              </Col>
              <Col sm="4" style={{ textAlign: "center" }}>
                <label>Liczba drogerii: {allShops.length}</label>
              </Col>
              <Col sm="4">
                {/* {isAdmin() && (
                  <Link
                    className="btn btn-primary margin-bottom-3"
                    to="/shopsImport"
                  >
                    Zaimportuj z pliku .xlsx
                  </Link>
                )} */}
              </Col>
            </Row>

            <Row>
              <Col lg="2" className="mt-3">
                {isAdmin() && (
                  <React.Fragment>
                    <Accordion
                      defaultActiveKey=""
                      activeKey={settingsMode ? "0" : "1"}
                      className="mt-2"
                    >
                      <Accordion.Item
                        eventKey="0"
                        className="border-primary border-"
                      >
                        <Accordion.Header
                          onClick={(event) => {
                            this.setState({ settingsMode: !settingsMode });
                          }}
                        >
                          <div className="w-100 text-center">
                            <span className="fw-bold">Ustawienia masowe</span>
                          </div>
                        </Accordion.Header>
                        <Accordion.Body>
                          <AddWifi
                            shopId={"all"}
                            onRefreshData={this.handleRefreshWifiData}
                            horizontal={true}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </React.Fragment>
                )}
              </Col>
              <Col lg="10">

                <Row>
                  <Col ms="12" xs="12">
                    <SearchBox
                      value={searchQuery}
                      onChange={this.handleSearch}
                    />
                  </Col>
                </Row>
                <Row className={"mt-1"}>
                  <Col ms="12" xs="12">
                    <Pagination
                        itemsCount={totalCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={this.handlePageChange}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col ms="12" xs="12">
                    <WifiTable
                      shops={shops}
                      sortColumn={sortColumn}
                      onEdit={this.handleEdit}
                      onAdd={this.handleAdd}
                      onSort={this.handleSort}
                      onRowClick={this.handleRowClick}
                      onRefreshData={this.handleRefreshWifiData}
                    />
                  </Col>
                </Row>

                <Row className={"mb-1"}>
                  <Col ms="12" xs="12">
                    <Pagination
                      itemsCount={totalCount}
                      pageSize={pageSize}
                      currentPage={currentPage}
                      onPageChange={this.handlePageChange}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </ConfirmationModalContextProvider>
      </React.Fragment>
    );
  }
}

export default WifiSettings;
