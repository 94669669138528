import React, {Component} from "react";
import Container from "react-bootstrap/Container";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


import {CardDeck} from "react-bootstrap";
import {toast} from "react-toastify";

import FileSaver from "file-saver";

import SimpleSelectLabel from "./common/simpleSelectLabel";
import SimpleSelect from "./common/simpleSelect";

import ConfirmationModalContextProvider from "./common/modalConfirmationContext";
import ConfirmationButton from "./common/confirmationButton";

import dictionary from "../PL.json";
import config from "../config.json";

import * as AuthDeviceService from "../services/authDeviceService";
import ShopService from "../services/shopService";
import * as LogService from "../services/logService";

import {prepareDateWithSec, prepareDateWithoutHours} from "../utils/dateFormat";

import {isAdminOrIsService} from "../services/authService";
import {date} from "joi";
import {result} from "lodash";
import Shop from "../services/shopService";
import {findShopsWithDevices} from "../utils/select";

class LogView extends Component {
    state = {
        shops: [],
        shopsToShow: [],
        shopAuthDevices: [],
        searchShopQuery: "",
        selectedShop: {},
        selectedDevice: {},
        logsJSON: [],
        logs: "",
        searchQuery: "",
        selectedDate: prepareDateWithoutHours(new Date(Date.now())),
    };

    async componentDidMount() {
        let shopId = null;
        if (this.props.match.params.shopId) {
            shopId = this.props.match.params.shopId;
        }

        await this.getShopData();

        if (!shopId) return;

        await this.getShopDevicesFromServer(shopId);
    }

    getShopData = async () => {
        try {
            const {data} = await Shop.getAllShopsAndDevices();
            const shops = findShopsWithDevices(data.allShops, data.devices);

            this.setState({shops, shopsToShow: shops});
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    getShopDevicesFromServer = async (shopId) => {
        try {
            let {data: shopAuthDevices} = await AuthDeviceService.getByShopId(shopId);
            shopAuthDevices = shopAuthDevices.map((device) => {
                return {...device, preattySN: device.sn + " " +  device.dev_type};
            });
            shopAuthDevices = shopAuthDevices.sort((a,b) => Number(a.sn) - Number(b.sn));

            this.setState({shopAuthDevices});
        } catch (err) {
            toast.error("Błąd serwera!!!");
        }
    };

    handleShopChange = async ({currentTarget: input}) => {
        const selectedShop = input.value;
        // let selectedShop = this.state.shops.find((shop) => shop._id === input.value);
        this.setState({selectedShop});
        if (selectedShop) {
            await this.getShopDevicesFromServer(selectedShop);
        } else {
            this.setState({shopAuthDevices: [], selectedDevice: {}});
        }
    };

    handleDeviceChange = async ({currentTarget: input}) => {
        const selectedDevice = input.value;
        this.setState({selectedDevice});
    };

    handleDateChange = async ({currentTarget: input}) => {
        const value = input.value;
        const date = new Date(value);
        this.setState({selectedDate: date});
    };

    formatLogToTxt = (log) => {
        let result = "";

        result += prepareDateWithSec(log.timestamp * 1000) + ";";
        result += log.type + ";";
        result += log.sn + ";";
        result += "C:" + log.event + ";";
        result += log.message + ";\n";
        return result;
    };

    handleEksport = async () => {
        const {logsJSON} = this.state;

        let result = "";
        logsJSON.forEach((log) => (result += this.formatLogToTxt(log)));
        var blob = new Blob([result], {
            type: "text/plain;charset=utf-8",
        });

        FileSaver.saveAs(blob, "log_" + (new Date(Date.now())).toLocaleString() + ".txt");
    };

    handleFilter = async () => {
        const {selectedDevice, shopAuthDevices, selectedDate} = this.state;

        if (this.state.selectedShop) {
            const data = {};
            if (selectedDevice) {
                const device = shopAuthDevices.find((device) => device._id === selectedDevice);
                if (device) data.sn = device.sn;
                if (selectedDate) data.day = selectedDate;
            }

            console.log(data.day);
            const {data: logs} = await LogService.getByShopId(this.state.selectedShop, data);
            if (logs && logs.length > 0) {
                const logsJSON = [];
                //! rozbijanie eventów z paczki na pojedyncze logi
                logs.forEach((log) => {
                    const {sn, fw, hw, type, events, ip, rssi, heap} = log;

                    events.forEach(({t: timestamp, e: event, m: message}) => {
                        let service = false;
                        let no = event % 1000;
                        const result = config.LOGS_DEF.findIndex((def) => def.no === no);
                        if (result !== -1) {
                            message = dictionary.logs.LOGS_DEF[config.LOGS_DEF[result].message] + " " + message;
                            service = config.LOGS_DEF[result]?.service ? true : false;
                        }


                        const logJSON = {sn, fw, hw, type, timestamp, event, message, service};
                        if (ip !== undefined) logJSON.ip = ip;
                        if (rssi !== undefined) logJSON.rssi = rssi;
                        if (heap !== undefined) logJSON.heap = heap;
                        logsJSON.push(logJSON);
                    });
                });
                this.setState({logsJSON: logsJSON.reverse()});
            } else {
                this.setState({logsJSON: []});
            }
        }
    };

    handleSearchShop = async (query) => {
        const shops = this.filterShopsList(query);

        if (shops.length > 0) {
            this.setState({shopsToShow: shops, selectedShop: shops[0]._id, searchQuery: query});
            await this.getShopDevicesFromServer(shops[0]._id);
        } else {
            this.setState({
                shopsToShow: shops,
                selectedShop: "",
                shopAuthDevices: [],
                searchQuery: query,
            });
        }
    };

    filterShopsList = (searchQuery) => {
        const {shops: allShops} = this.state;

        if (searchQuery) {
            const shops = allShops.filter((shop) => {
                let result = false;
                let shopNo = Number(shop.name);
                let query = Number(searchQuery);
                if (query != null && shopNo != null) {
                    result = shopNo === query;
                }
                return result;
            });

            let selectedShop = this.state.data;
            if (shops.length > 0) {
                selectedShop = shops[0]._id;
            } else {
                selectedShop = "";
            }
            this.setState({selectedShop});
            return shops;
        }

        return allShops;
    };

    generateDate = () => {
        let selectedDate = this.state.selectedDate;
        const date = prepareDateWithoutHours(selectedDate);
        return date;
    };

    formatLogs = (log, index) => {
        let colorMark = log.service ? "#942911" : "#f5cb42";
        let colorService = log.service ? "#942911" : "#000000";
        let colorLine = index % 2 === 0 ? "rgba(0, 0, 0, 0.05)" : "rgba(0, 0, 0, 0.3)";
        return (
            <p
                key={"log_" + index}
                style={{color: "black", padding: "0", margin: "0", backgroundColor: colorLine}}
            >
                <b style={{color: "#121619"}}> {prepareDateWithSec(log.timestamp * 1000)} </b>
                <mark
                    style={{
                        backgroundColor: colorMark,
                        display: "inline-block",
                        padding: "7px",
                        borderRadius: "4px",
                        fontSize: "12px",
                    }}
                >
                    {/* { sn, fw, hw,type, timestamp, event, message }; */}
                </mark>
                {" " + log.type + " " + log.sn}
                <b style={{color: colorService}}>{" " + log.message + " "}</b>
                {"    C:" + log.event + " "}
                {log.rssi ? "RSSI:" + log.rssi + " " : ""}
                {log.ip ? "IP:" + log.ip + " " : ""}
                {log.heap ? "M:" + log.heap + " " : ""}
                {log.fw ? "FW:" + log.fw + " " : ""}
                {log.hw ? "HW:" + log.hw + " " : ""}
            </p>
        );
    };

    // log.message +
    // "; KOD: " +
    // log.meta.event +
    // "\n";

    render() {
        const {
            shops,
            shopsToShow,
            shopAuthDevices,
            selectedShop,
            selectedDevice,
            logsJSON,
            searchQuery,
            selectedDate,
        } = this.state;

        return (
            <React.Fragment>
                <Breadcrumb style={{backgroundColor: "#DDDDDD"}}>
                    <Breadcrumb.Item
                        style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                        href="/"
                    >
                        Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item
                        style={{marginTop: 3, marginLeft: 3, marginBottom: 3, marginRight: 0}}
                        active
                    >
                        Logi
                    </Breadcrumb.Item>
                </Breadcrumb>
                <Container fluid>
                    <Row md="12" xs="12" className="mx-1">
                        <Col lg="3" md="4">
                            <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                <label className="align-self-center fw-bold">Drogeria:</label>
                                <SimpleSelect
                                    value={selectedShop}
                                    className="m-2 w-100"
                                    style={{minWidth: "60px"}}
                                    optionName="name"
                                    optionKey="_id"
                                    options={shopsToShow}
                                    onChange={this.handleShopChange}
                                />
                                <input
                                    type="text"
                                    name="query"
                                    className="w-100 m-2"
                                    onChange={(e) => this.handleSearchShop(e.currentTarget.value)}
                                    value={searchQuery}
                                    placeholder={"Szukaj..."}
                                />
                            </div>
                            {shopAuthDevices.length > 0 ? (
                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <label className="align-self-center fw-bold">Urządzenie:</label>
                                    <SimpleSelect
                                        value={selectedDevice}
                                        className="m-2 w-100"
                                        optionName="preattySN"
                                        optionKey="_id"
                                        options={shopAuthDevices}
                                        onChange={this.handleDeviceChange}
                                    />
                                </div>
                            ) : null}
                            {shopAuthDevices.length > 0 ? (
                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <label className="align-self-center fw-bold">Dzień:</label>
                                    <input
                                        className="m-2 w-100"
                                        value={this.generateDate()}
                                        name="data"
                                        type="date"
                                        onChange={this.handleDateChange}
                                    />
                                </div>
                            ) : null}
                            {shopAuthDevices.length > 0 ? (
                                <React.Fragment>
                                    <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                        <Button onClick={this.handleFilter} size="sm" className="mb-2 me-2 w-100">
                                            Pokaż
                                        </Button>
                                    </div>
                                    {logsJSON.length > 0 ? (
                                        <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                            <p className="align-self-center fw-bold">
                                                Znalezionych wpisów: {logsJSON.length}
                                            </p>
                                        </div>
                                    ) : null}
                                </React.Fragment>
                            ) : null}
                        </Col>
                        <Col
                            lg="9"
                            md="8"
                            className="overflow-auto"
                            style={{
                                height: "500px",
                                padding: "0",
                                backgroundColor: "rgba(0, 0, 0, 0.05)",
                                border: "1px solid rgba(0, 0, 0, 0.3)",
                            }}
                        >
                            {logsJSON.length > 0
                                ? logsJSON.map((log, index) => this.formatLogs(log, index))
                                : null}
                        </Col>
                    </Row>
                    {logsJSON.length > 0 ? (
                        <Row>
                            <Col lg="3" md="4"></Col>
                            <Col lg="9" md="8">
                                <div className="d-flex form-group justify-content-center flex-row flex-nowrap">
                                    <Button
                                        onClick={this.handleEksport}
                                        size="sm"
                                        variant="success"
                                        className="mb-2 mt-2 me-2 w-100"
                                    >
                                        Eksportuj
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                </Container>
            </React.Fragment>
        );
    }
}

export default LogView;
